<template>
  <div>
    <v-layout wrap justify-center class="mainfont">
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#283e51"
        spinner="spinner"
      />
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs11 pt-5>
            <span style="font-size: 25px;font-weight: bolder;">ADD IB</span>
          </v-flex>
          <v-flex xs11 pt-5>
            <v-card
              elevation="0"
              class="mainfont"
              style="color: #000000; font-size: 16px; font-weight: 500"
            >
              <v-layout wrap justify-center>
                <v-flex>
                  <v-layout pa-4 wrap justify-center>
                    <v-flex xs3 pt-6>
                      <v-form @submit.prevent="validateInput">
                        <v-text-field
                          style="border-radius: 0%;"
                          color="#283e51"
                          outlined
                          label="IB Name"
                          :rules="ibnameRules"
                          background-color="white"
                          v-model="accName"
                          hide-details="auto"
                        ></v-text-field>
                      </v-form>
                    </v-flex>

                    <v-flex xs3 pt-6 pl-4>
                      <v-form @submit.prevent="validateInput">
                        <v-text-field
                          style="border-radius: 0%;"
                          color="#283e51"
                          outlined
                          background-color="white"
                          label="Max Guests Per Room"
                          type="number"
                          class="inputPrice"
                          v-model="maxGuestPerRoom"
                          hide-details
                        ></v-text-field>
                      </v-form>
                    </v-flex>

                    <v-flex xs3 pt-6 pl-4>
                      <v-form @submit.prevent="validateInput">
                        <v-text-field
                          style="border-radius: 0%;"
                          color="#283e51"
                          outlined
                          background-color="white"
                          label="Number  of Rooms"
                          type="number"
                          class="inputPrice"
                          v-model="noOfRooms"
                          hide-details
                        ></v-text-field>
                      </v-form>
                    </v-flex>

                    <v-flex xs3 pl-4 pt-6>
                      <v-form @submit.prevent="validateInput">
                        <v-text-field
                          style="border-radius: 0%"
                          color="#283e51"
                          outlined
                          background-color="white"
                          label="Rate"
                          type="number"
                          v-model="rate"
                          class="inputPrice"
                          hide-details="auto"
                        ></v-text-field>
                      </v-form>
                    </v-flex>

                    <v-flex xs3 pt-5>
                      <v-form @submit.prevent="validateInput">
                        <v-select
                          style="border-radius: 0%;"
                          color="#283e51"
                          outlined
                          :items="Dist"
                          background-color="white"
                          label="District"
                          v-model="district"
                          hide-details
                        ></v-select>
                      </v-form>
                    </v-flex>

                    <v-flex xs3 pt-5 pl-4>
                      <v-form @submit.prevent="validateInput">
                        <v-autocomplete
                          outlined
                          style="border-radius: 0%;"
                          label="Circle"
                          color="black"
                          :items="circle"
                          v-model="circleid"
                          item-text="officeName"
                          item-value="_id"
                          item-color="#FF1313"
                          hide-details
                          clearable
                          class="custom-autocomplete  mainfont"
                        >
                          <template v-slot:append>
                            <v-icon color="black">mdi-chevron-down</v-icon>
                          </template>
                        </v-autocomplete>
                      </v-form>
                    </v-flex>

                    <v-flex xs3 pt-5 pl-4>
                      <v-form @submit.prevent="validateInput">
                        <v-autocomplete
                          style="border-radius: 0%;"
                          outlined
                          label="Division"
                          color="black"
                          :items="division"
                          v-model="divisionid"
                          item-text="officeName"
                          item-value="_id"
                          item-color="#FF1313"
                          hide-details
                          clearable
                          class="custom-autocomplete  mainfont"
                        >
                          <template v-slot:append>
                            <v-icon color="black">mdi-chevron-down</v-icon>
                          </template>
                        </v-autocomplete>
                      </v-form>
                    </v-flex>

                    <v-flex xs3 pt-5 pl-4>
                      <v-form @submit.prevent="validateInput">
                        <v-autocomplete
                          style="border-radius: 0%;"
                          outlined
                          label="Range"
                          color="black"
                          :items="range"
                          v-model="rangeid"
                          item-text="officeName"
                          item-value="_id"
                          item-color="#FF1313"
                          hide-details
                          clearable
                          class="custom-autocomplete  mainfont"
                        >
                          <template v-slot:append>
                            <v-icon color="black">mdi-chevron-down</v-icon>
                          </template>
                        </v-autocomplete>
                      </v-form>
                    </v-flex>

                    <v-flex xs12 pt-8>
                      <span>Add Caption</span><br />
                      <vue-editor v-model="caption" 
                      ></vue-editor>
                      <div v-if="captionError" style="color: red;">{{ captionError }}</div>
                    </v-flex>

                    <v-flex xs12 text-right pt-9 pb-10>
                      <v-btn
                        @click="addIb()"
                        dark
                        width="200px"
                        color="#283e51"
                      >
                        <span style="color: white; font-size: 14px"
                          >SAVE CHANGES</span
                        >
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      name: null,
      barlist: [],
      userProfileTabs: null,
      subtab: null,
      rate:"",
      Dist: [
        "Thiruvananthapuram",
        "Kollam",
        "Pathanamthitta",
        "Alappuzha",
        "Kottayam",
        "Idukki",
        "Ernakulam",
        "Thrissur",
        "Palakkad",
        "Malappuram",
        "Kozhikode",
        "Wayanad",
        "Kannur",
        "Kasaragod",
      ],
      district:null,
      appLoading: false,
      page: 1,
      pages: 0,
      dialog1: false,

      preview: null,
      tab: null,
      barlist1: [],
      dialog3: false,
      deletedialog: false,
      editdailog: false,

      minAmount: null,
      password: null,
      categoryarray: [],
      list: [],
      flag: false,
      flag2: false,
      auctionbased: [],
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      limit: 10,
      noOfRooms: null,
      accName: null,
      maxGuestPerRoom: null,
      caption: null,
      rangeid: null,
      circleid: null,
      range: [],
      divisionid: null,
      msg: null,
      currentPage: 1,
      division: [],
      showSnackBar: false,
      circle: [],
      dialog2: false,
      captionError: '',
    };
  },
  watch: {
    circleid() {
      this.getDivision();
    },
    // caption() {
    //   this.validateCaption();
    // },

    divisionid() {
      this.getRange();
    },
  },
  computed: {
    ibnameRules() {
    return [
      (v) => !!v || "Please enter the IB name",
      (v) => /^[a-zA-Z0-9\s]+$/.test(v) || "IB Name must contain only alphanumeric characters",
      ];
    },
},

  mounted() {
    // this.getList();
    this.getCircle();
  },

  methods: {

    // validateCaption() {
    //   var alpha =/^[a-zA-Z]*$/g;
    //   if(!this.caption.match(alpha))
    //   {
    //     console.log("hloo",this.caption)
    //     this.captionError = "Please provide alphabets";
    //       return;
    //   }
    // },

    addIb() {
      if(this.noOfRooms==null || this.accName==null || this.maxGuestPerRoom==null || this.district==null || this.rangeid==null || this.rate==null || this.caption==null){
        this.msg = "Please fill all the fields";
        this.showSnackBar = true;
        return;
      }
      axios({
        method: "POST",
        url: "/add/accomodation",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          circleid: this.circleid,
          divisionid: this.divisionid,
          noOfRooms: this.noOfRooms,
          accName: this.accName,
          maxGuestPerRoom: this.maxGuestPerRoom,
          district: this.district,
          caption: this.caption,
          rangeid: this.rangeid,
          perdayrate: this.rate,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.dialog2 = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    getCircle() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/get/circles",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {},
      })
        .then((response) => {
          this.circle = response.data.data;
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    getDivision() {
      this.appLoading = true;
      const selectedCircle = this.circle.find((c) => c._id === this.circleid);
      if (selectedCircle) {
        const path = selectedCircle.path;
        axios({
          method: "post",
          url: "/get/divsions",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            circleid: this.circleid,
            path: path, // Include the 'path' parameter here
          },
        })
          .then((response) => {
            this.division = response.data.data;
            this.appLoading = false;
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      }
    },

    getRange() {
      this.appLoading = true;
      // Find the selected division based on divisionid
      const selectedDivision = this.division.find(
        (d) => d._id === this.divisionid
      );
      if (selectedDivision) {
        // Extract the path from the selected division
        const path = selectedDivision.path;

        axios({
          method: "post",
          url: "/get/range",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            divisionid: this.divisionid,
            path: path, // Include the 'path' parameter here
          },
        })
          .then((response) => {
            this.range = response.data.data;
            this.appLoading = false;
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      }
    },
  },
};
</script>

<style>
.no-spinners {
  /* Remove the up and down arrows */
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>